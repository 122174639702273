
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../Context/PageLoader';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../../api'
import {
    CREATE_REAL_ESTATE_HOLDINGS, GET_REAL_ESTATE_HOLDINGS_BY_ID, UPDATE_REAL_ESTATE_HOLDINGS_BY_ID, ARCHIVE_INVESTMENT_BY_ID,
    DELETE_INVESTMENT_BY_ID, GET_REMINDER_LIST
} from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, TitleTrim, ReminderTaskIcon, formatDateAndTime } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import { FormField, SelectField, CurrencyField, TextAreaField, DatePickerField, SystemInfo, ViewDiv, EINField } from '../../../components/Forms/FormFields';
import ImageUpload from '../../../components/DocumentManager/ImageUpload';
import TabComponent from '../../../components/TabComponent';
import Footer from '../../../components/Footer';
import ProfessionalContacts from '../../../components/ProfessionalContacts';
import CapitalCalls from '../../../components/CapitalCalls';
import { useReactToPrint } from 'react-to-print';
import ReminderTab from '../../../components/Reminder/ReminderTab';

export const realEstateHoldingsSchema = Yup.object().shape({
    investment_name: Yup.string().label('Legal Entity Name').trim().required('Legal Entity Name is required'),
    property_type: Yup.string().label('Property Type').required('Property Type is required'),
    investment_date: Yup.date().label('Investment Date').required('Investment Date required'),
    // building_square_feet: Yup.string().label('Property Size').trim().required('Property Size is required'),
    // parcel_size_acreage: Yup.string().label('Parcel Size/Acreage').trim().required('Parcel Size/Acreage is required'),
    property_address: Yup.string().label('Property Address').trim().required('Property Address is required'),
    manager: Yup.string().label('Managing Partner or President').trim().required('Managing Partner or President is required'),
    // lender_name: Yup.string().label('Lender Name').trim().required('Lender Name is required'),
    // loan_number: Yup.string().label('Loan Number').trim().required('Loan Number is required'),
    current_value: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Current value is required'),
    // class_a_members: Yup.string().label('Class A Members').trim().required('Class A Members is required'),
    // cash_distributions: Yup.string().label('Cash Distributions').trim().required('Cash Distributions is required'),
    // exit_strategy : Yup.string().label('Exit Strategy').trim().required('Exit Strategy is required'),
    amount_invested: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Amount Invested is required'),
    // amount_borrowed: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Amount Borrowed is required'),
    state_of_formation: Yup.string().label('State of Formation').trim().required('State of Formation is required'),
    ein: Yup.string().matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX').nullable(true)
});


function AddRealEstateHoldings({ props }) {
    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        investment_name: '',
        property_type: '',
        investment_date: '',
        building_square_feet: '',
        property_address: '',
        manager: '',
        lender_name: '',
        loan_number: '',
        current_value: '',
        is_active: true,
        investment_category: process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID,
        class_a_members: '',
        cash_distributions: '',
        exit_strategy: '',
        amount_invested: '',
        amount_borrowed: '',
        property_image_remove: false,
        is_archived: '',
        state_of_formation: '',
        parcel_size_acreage: '',
        year_built: 'YYYY',
        property_tax_id: '',
        carrier: '',
        policy_number: '',
        amount_insured: '',
        expiration_date: '',
        premium: '',
        url: '',
        ein: '',
        state_country_of_incorporation: '',
        ownership_percentage: '',
        cal_current_value: '',
        contingent_liability: '',
    });
    const [editMode, setEditMode] = useState(false);
    const [resetImageSection, setResetImageSection] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [imageRemove, setImageRemove] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isArchivedView, setIsArchivedView] = useState(false);
    const [isImageFieldClear, setIsImageFieldClear] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [capitalList, setCapitalList] = useState([]);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const [printMode, setPrintMode] = useState(false);
    const [incomeComplete, setIncomeComplete] = useState(false)
    const [expenseComplete, setExpenseComplete] = useState(false)
    const [showErrorIcon, setShowErrorIcon] = useState(false);

    const category_id = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;


    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-real-estate-holdings/${investmentID}`, { state: { previousPage: "Real Estate Holdings View", previousUrl: `/view-real-estate-holdings/${investmentID}` } });
    }

    function deleteRealEstateHoldings(id, Name) {
        confirmAlert({
            title: 'Delete Real Estate Holdings',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleBackClick = (investmentId, pageUrl) => {
        setImageRemove(false);
        setSelectedImage('')
        if (pageUrl) {
            history(pageUrl);
        }
        else {
            history('/real-estate-holding-list');
        }
    };

    useEffect(() => {
        setImageRemove(false);
        setSelectedImage('')
    }, [urlName])

    const handleDelete = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));
            if (deleteInvestment) {
                history('/real-estate-holding-list')
            }
            notify(`${investment_name} successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
        }

    };


    const archivedRealEstateHoldings = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive Real Estate Holdings',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true })

            if (archive) {
                history('/real-estate-holding-list')
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while deleteing the error : ', error)
        }

    };

    const notify = (message, type) => toast(message, { type });

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };

    useEffect(() => {
        // Check and update permissions
        authCheckAndUpdate();

        if (urlName == 'view-real-estate-holdings') {
            setViewMode(true)
            setEditMode(false);
            setViewAction(true);
            setPreviousPage('Real Estate Holdings List')
            setPreviousUrl('/real-estate-holding-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     console.log('check=========',checkPermission(userPermissions, 'view-real-estate-hld'),userPermissions);
            //     if(!checkPermission(userPermissions, 'view-real-estate-hld') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/portfolio-summary')
            //     }
            // }
        } else if (urlName == 'edit-real-estate-holdings') {
            setViewMode(false)
            setEditMode(true)
            setViewAction(false)
            setPreviousPage('Real Estate Holdings List')
            setPreviousUrl('/real-estate-holding-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     if(!checkPermission(userPermissions, 'view-real-estate-hld') ){
            //         notify("Sorry, you do not have permission to access this1.", 'warning')
            //         history('/portfolio-summary')
            //     }else if(!checkPermission(userPermissions, 'edit-real-estate-hld') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/stock-list')
            //     }
            // }
        }

        // window.scrollTo(0, 0);
        const fetchInvestmentById = async () => {
            // setLoading(true);
            if (location.state) {
                setPreviousPage(location.state.previousPage)
                setPreviousUrl(location.state.previousUrl)
            } else {
                setLoading(false);
            }
            if ((urlName == 'edit-real-estate-holdings') || (urlName == 'view-real-estate-holdings')) {

                try {
                    setLoading(true);
                    const response = await get(concatUrlWithId(GET_REAL_ESTATE_HOLDINGS_BY_ID, investmentID));
                    if (response) {
                        let values = {
                            ...response,
                            investment_id: response.id,
                            property_type: response['real_estate_holding_details.property_type'],
                            investment_date: formatDateAndTime(response['real_estate_holding_details.investment_date'], 'YYYY-MM-DD'),
                            property_address: response['real_estate_holding_details.property_address'],
                            building_square_feet: response['real_estate_holding_details.building_square_feet'],
                            manager: response['real_estate_holding_details.manager'],
                            lender_name: response['real_estate_holding_details.lender_name'],
                            loan_number: response['real_estate_holding_details.loan_number'],
                            current_valuation: response['current_value'],
                            current_value: response['current_value'],
                            class_a_members: response['real_estate_holding_details.class_a_members'],
                            cash_distributions: response['real_estate_holding_details.cash_distributions'],
                            exit_strategy: response['real_estate_holding_details.exit_strategy'],
                            property_image: response['real_estate_holding_details.property_image'],
                            auditLog: response?.investmentAuditLog?.investment_headers?.current_value,
                            investmentAuditLog: response?.investmentAuditLog?.investment_headers?.amount_invested,
                            state_of_formation: response['real_estate_holding_details.state_of_formation'] || '',
                            parcel_size_acreage: response['real_estate_holding_details.parcel_size_acreage'],
                            year_built: response['real_estate_holding_details.year_built'],
                            property_tax_id: response['real_estate_holding_details.property_tax_id'],
                            carrier: response['real_estate_holding_details.carrier'],
                            policy_number: response['real_estate_holding_details.policy_number'],
                            expiration_date: formatDateAndTime(response['real_estate_holding_details.expiration_date'], 'YYYY-MM-DD'),
                            amount_insured: response['real_estate_holding_details.amount_insured'],
                            premium: response['real_estate_holding_details.premium'],
                            url: response['real_estate_holding_details.url'] || '',
                            ein: response['ein'] || '',
                            state_country_of_incorporation: response['real_estate_holding_details.state_country_of_incorporation'],
                            ownership_percentage: response['real_estate_holding_details.ownership_percentage'] || '',
                            // cal_current_value: response['real_estate_holding_details.cal_current_value'],
                        };

                        setContacts(response.professional_contacts);
                        setCapitalList(response.capital_calls);
                        setInitialValues(values);
                        await getReminderTaskCount();
                        if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                            setUserPermissions(response.permission_keys[0].permission_keys)
                        };

                        if (response.is_archived == true) {
                            setViewAction(false)
                            setIsArchivedView(true)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    }
                } catch (error) {
                    console.log('Error while throwing:: ', error);
                    notify(error?.response?.data?.message, 'error');
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
        fetchInvestmentById();

    }, [urlName]);

    const propertyTypes = [
        { value: 'Commercial', label: 'Commercial' },
        { value: 'Industrial', label: 'Industrial' },
        { value: 'Raw Land', label: 'Raw Land' },
        { value: 'Residential', label: 'Residential' },
        { value: 'Special Use', label: 'Special Use' },
        { value: 'Others', label: 'Others' },
    ];

    const handleImageChange = async (image) => {
        setSelectedImage(image);
    }
    const handleImageRemove = async (value) => {
        setImageRemove(value);
        setSelectedImage('');
    }
    const resetImageFlag = async (value) => {
        setResetImageSection(value)
    }

    const handleFieldClear = async (value) => {
        setIsImageFieldClear(value)
    }

    const hangleValueChange = (type, value) => {
        setInitialValues(prevState => ({
            ...prevState,
            [type]: value
        }))
    }

    const handleCollectedContacts = (updatedContacts) => {
        setContacts(updatedContacts);
    };

    const handleCollectedCapital = (updatedCapital) => {
        setCapitalList(updatedCapital);
    };

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: investmentID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }

    const updateReminderData = async () => {
        await getReminderTaskCount();
    }

    const handleBeforePrint = () => {
        // setBeforePrintStatus(true)
        setPrintMode(true);
    };
    const checkValidation = () => {
        setShowErrorIcon(true);
    }

    const handleAfterPrint = () => {
        setLoading(false);
        setIncomeComplete(false)
        setExpenseComplete(false)
        setPrintMode(false);
    };

    const printError = (error) => {
        // console.log('Coming inside the print error::: ',error )
    }

    const beforeprint = (error) => {
        console.log('Error in the before print ::', error)
    }

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        beforeprint: beforeprint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.investment_name || 'investment',
        onPrintError: printError
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }


    const tryHandlePrint = () => {
        try {
            handlePrint()
        } catch (error) {
            console.log('Erro int he tryhandleprint funciton', error)
            printError(error);
        }
    };

    const onTabLoadingComplete = (value) => {
        try {
            if (!incomeComplete || !expenseComplete) {
                if (value == "INCOME") {
                    setIncomeComplete(true)
                } else {
                    setExpenseComplete(true)
                }
            }
        } catch (error) {

        }
    }

    const onDataLoadingComplete = () => {
        try {
            setTimeout(() => {
                tryHandlePrint()
            }, 500);
        } catch (error) {
            console.log('error in the value :: ', error)
        }
    }

    useEffect(() => {
        if (incomeComplete && expenseComplete) {
            onDataLoadingComplete();
        }
    }, [incomeComplete, expenseComplete]);

    return (
        <>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
            <div className="container-fluid" style={{ marginTop: '20px' }}>

                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix">
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : '')}`} <TitleTrim title={initialValues.investment_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Real Estate Holdings' : (viewMode ? 'View Real Estate Holdings' : 'Add Real Estate Holdings')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={formRef}>
                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={realEstateHoldingsSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        setLoading(true);
                        try {
                            let response = []
                            const url = editMode ? concatUrlWithId(UPDATE_REAL_ESTATE_HOLDINGS_BY_ID, investmentID) : CREATE_REAL_ESTATE_HOLDINGS;
                            const bodyPayload = {
                                investment_name: values.investment_name,
                                investment_category: values.investment_category,
                                property_type: values.property_type,
                                investment_date: values.investment_date,
                                building_square_feet: values.building_square_feet,
                                property_address: values.property_address,
                                manager: values.manager,
                                lender_name: values.lender_name,
                                loan_number: values.loan_number,
                                current_valuation: values.current_value,
                                current_value: values.current_value,
                                class_a_members: values.class_a_members,
                                cash_distributions: values.cash_distributions,
                                exit_strategy: values.exit_strategy,
                                amount_invested: values.amount_invested,
                                amount_borrowed: values.amount_borrowed,
                                property_image: editMode ? initialValues.property_image : '',
                                property_image_remove: editMode && imageRemove,
                                state_of_formation: values.state_of_formation || '',
                                parcel_size_acreage: values.parcel_size_acreage,
                                year_built: values.year_built == 'YYYY' ? null : values.year_built,
                                property_tax_id: values.property_tax_id,
                                carrier: values.carrier,
                                policy_number: values.policy_number,
                                amount_insured: values.amount_insured,
                                expiration_date: values.expiration_date,
                                premium: values.premium,
                                professional_contacts: JSON.stringify(contacts), 
                                capital_calls: JSON.stringify(capitalList), 
                                url: values.url || '',
                                ein: values.ein || '',
                                state_country_of_incorporation: values.state_country_of_incorporation || '',
                                ownership_percentage: values.ownership_percentage || '',
                                contingent_liability: values.contingent_liability || '',
                            };

                            // Create a new FormData object
                            const formData = new FormData();

                            // Iterate through the keys of the bodyPayload object and append each key-value pair to the FormData object
                            for (const key in bodyPayload) {
                                if (bodyPayload.hasOwnProperty(key)) {
                                    formData.append(key, bodyPayload[key]);
                                }
                            }

                            formData.append('file', selectedImage);

                            response = editMode ? await put(url, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }) : await post(url, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });

                            if (response) {
                                notify(`Real Estate Holdings ${values.investment_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')
                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setIsImageFieldClear(true)
                                    setContacts([]);
                                    setCapitalList([]);
                                    setShowErrorIcon(false)
                                } else {
                                    setIsImageFieldClear(true)
                                    setImageRemove(false);
                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/real-estate-holding-list', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }

                            } else {
                                editMode ? notify(`Real Estate Holdings update failed...`, 'error') : notify(`Create Stock failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');

                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                        setLoading(false);
                    }}
                >
                    {props => (<div className="main-content introduction-farm">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid">
                                    <div className="card">
                                        <div className={`card-body ${viewMode ? 'view-mode bg-white' : 'add-mode'}`}>
                                            {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            }
                                            {viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                                <div className='no-print' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint(); }} ></i>
                                                    </OverlayTrigger>
                                                    {<ReminderTaskIcon
                                                        iconClass="no-print"
                                                        tooltip={`Set/View Reminders `}
                                                        onClick={() => setShowReminderTask(true)}
                                                        backgroundColor="#8098ad"
                                                        count={reminderTaskCount}
                                                    />}

                                                    {(checkPermission(userPermissions, 'edit-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Real Estate Holdings</Tooltip>} >
                                                        <i className="bx bxs-edit btn btn-circle" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'archive-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive Real Estate Holdings</Tooltip>} >
                                                        <i className="zmdi zmdi-archive btn btn-circle" onClick={() => archivedRealEstateHoldings(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'delete-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Real Estate Holdings</Tooltip>} >
                                                        <i className="bx bx-trash btn btn-circle" onClick={() => deleteRealEstateHoldings(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                    </OverlayTrigger>}
                                                </div>
                                            </div>
                                            }

                                            <Form>
                                                {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                        <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Real Estate Holdings - {initialValues.investment_name}</h4>
                                                    </div>
                                                </div>}
                                                <div className='row print-font-header'>
                                                    <div className='col-6'>
                                                        {viewMode && <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                            {initialValues.property_image && <div className='mb-4'>
                                                                <legend className='text-bold lenged-view'> Property Image</legend>
                                                                <div className='img-container'>
                                                                    <img src={initialValues.property_image} className="centered-image inv-image-height" />
                                                                </div>
                                                            </div>}
                                                            <div >
                                                                <legend className='text-bold lenged-view'> Investment Details </legend>
                                                                <ViewDiv fieldLabel={"Lender Name"} fieldValue={initialValues.lender_name} type={"text"} />
                                                                <ViewDiv fieldLabel={"Loan Number"} fieldValue={initialValues.loan_number} type={"text"} />
                                                                <ViewDiv fieldLabel={"Investment Date"} fieldValue={initialValues.investment_date} type={"date"} />
                                                                <ViewDiv fieldLabel={"Amount Invested($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Mortgage/Bank Debt($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                                <ViewDiv fieldLabel={"Current Valuation($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Investment Value after Debt($)"} fieldValue={initialValues.cal_current_value} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Ownership Percentage(%)"} fieldValue={initialValues.ownership_percentage || ""} type={"text"} />
                                                                <ViewDiv fieldLabel={"Class A Members"} fieldValue={initialValues.class_a_members || ""} type={"text"} />
                                                                <ViewDiv fieldLabel={"Cash Distributions"} fieldValue={initialValues.cash_distributions || ""} type={"text"} />

                                                                <legend className='text-bold lenged-view'> System Information</legend>
                                                                <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                                <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['investment_headers_created_by.full_name']} type={"text"} />
                                                                <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['investment_headers_modified_by.full_name']} type={"text"} />

                                                                {(!initialValues.property_image && viewMode) && <>
                                                                    {/* <legend className='text-bold lenged-view'> Cash Flow </legend>
                                                                <ViewDiv fieldLabel={"Total Income($)"} fieldValue={initialValues.totalIncome} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Total Expense($)"} fieldValue={initialValues.totalExpense} type={"currency"} /> */}
                                                                    <legend className='text-bold lenged-view'> Insurance Information </legend>
                                                                    <ViewDiv fieldLabel={"Carrier"} fieldValue={initialValues.carrier} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Policy Number"} fieldValue={initialValues.policy_number} type={"text"} />
                                                                    <ViewDiv fieldLabel={"Amount Insured($)"} fieldValue={initialValues.amount_insured} type={"currency"} />
                                                                    <ViewDiv fieldLabel={"Expiration Date"} fieldValue={initialValues.expiration_date} type={"date"} />
                                                                    <ViewDiv fieldLabel={"Premium($)"} fieldValue={initialValues.premium} type={"currency"} />
                                                                </>}

                                                            </div>
                                                        </div>}
                                                        {(editMode || (!viewMode && !editMode)) &&
                                                            <fieldset>
                                                                <legend className='text-bold'>Property Image</legend>
                                                                <ImageUpload viewMode={viewMode} editMode={editMode} fieldName="property_image" handleImageChange={handleImageChange} handleImageRemove={handleImageRemove} setFieldValue={props.setFieldValue}
                                                                    value={initialValues.property_image} resetImage={resetImageSection} resetImageFlag={resetImageFlag} isImageFieldClear={isImageFieldClear} handleFieldClear={handleFieldClear} />
                                                            </fieldset>}
                                                        {!viewMode && <fieldset>
                                                            <legend className='text-bold'>General Information</legend>

                                                            <FormField viewMode={viewMode} fieldName="investment_name" label="Legal Entity Name" value={initialValues.investment_name} maxLength={200} />

                                                            <EINField viewMode={viewMode} fieldName="ein" label="EIN" value={initialValues.ein} maxLength={10} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="state_country_of_incorporation" label="State/Country of Incorporation" value={initialValues.state_country_of_incorporation} maxLength={200} isRequired={false} />

                                                            <SelectField viewMode={viewMode} fieldName="property_type" label="Property Type" value={initialValues.property_type} options={propertyTypes} values={props.values} setFieldValue={props.setFieldValue} />

                                                            <FormField viewMode={viewMode} fieldName="property_tax_id" label="Property Tax ID" value={initialValues.property_tax_id} maxLength={200} isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="building_square_feet" label="Building Square Feet" values={props.values} setFieldValue={props.setFieldValue} prefix='' isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="parcel_size_acreage" label="Parcel Size/Acreage" values={props.values} setFieldValue={props.setFieldValue} prefix='' isRequired={false} />

                                                            <TextAreaField viewMode={viewMode} fieldName="property_address" label="Property Address" value={initialValues.property_address} maxLength={200} isRequired={true} />

                                                            <FormField viewMode={viewMode} fieldName="manager" label="Managing Partner or President" value={initialValues.manager} maxLength={200} />

                                                            <FormField viewMode={viewMode} fieldName="state_of_formation" label="State of Formation" value={initialValues.state_of_formation} maxLength={200} />

                                                            <DatePickerField viewMode={viewMode} fieldName="year_built" label="Year Built" value={initialValues.year_built} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                                isYear={true} isRequired={false} />

                                                            <TextAreaField viewMode={viewMode} fieldName="url" label="URL" value={initialValues.url} maxLength={2000} textAlign={true} />


                                                        </fieldset>}

                                                        {!viewMode &&
                                                            <fieldset>
                                                                <legend className='text-bold'> Additional Information </legend>
                                                                <TextAreaField viewMode={viewMode} fieldName="exit_strategy" label="Exit Strategy/Payout Details" value={initialValues.exit_strategy} maxLength={500} textAlign={true} />
                                                            </fieldset>}
                                                    </div>

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> General Information </legend>
                                                            <ViewDiv fieldLabel={"Legal Entity Name"} fieldValue={initialValues.investment_name} type={"text"} />
                                                            <ViewDiv fieldLabel={"EIN"} fieldValue={initialValues.ein} type={"text"} />
                                                            <ViewDiv fieldLabel={"State/Country of Incorporation"} fieldValue={initialValues.state_country_of_incorporation} type={"text"} />
                                                            <ViewDiv fieldLabel={"Property Type"} fieldValue={initialValues.property_type} type={"text"} />
                                                            <ViewDiv fieldLabel={"Property Tax ID"} fieldValue={initialValues.property_tax_id || ""} type={"text"} />
                                                            <ViewDiv fieldLabel={"Building Square Feet"} fieldValue={initialValues.building_square_feet} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Parcel Size/Acreage"} fieldValue={initialValues.parcel_size_acreage} type={"text"} />
                                                            <ViewDiv fieldLabel={"Property Address"} fieldValue={initialValues.property_address} type={"text"} />
                                                            <ViewDiv fieldLabel={"Managing Partner or President"} fieldValue={initialValues.manager} type={"text"} />
                                                            <ViewDiv fieldLabel={"State of Formation"} fieldValue={initialValues.state_of_formation} type={"text"} />
                                                            <ViewDiv fieldLabel={"Year Built"} fieldValue={initialValues.year_built || ""} type={"text"} />
                                                            <ViewDiv fieldLabel={"URL"} fieldValue={initialValues.url || ""} type={"url"} />


                                                            {(initialValues.property_image && viewMode) && <>
                                                                <legend className='text-bold lenged-view'> Insurance Information </legend>
                                                                <ViewDiv fieldLabel={"Carrier"} fieldValue={initialValues.carrier} type={"text"} />
                                                                <ViewDiv fieldLabel={"Policy Number"} fieldValue={initialValues.policy_number} type={"text"} />
                                                                <ViewDiv fieldLabel={"Amount Insured($)"} fieldValue={initialValues.amount_insured} type={"currency"} />
                                                                <ViewDiv fieldLabel={"Expiration Date"} fieldValue={initialValues.expiration_date} type={"date"} />
                                                                <ViewDiv fieldLabel={"Premium($)"} fieldValue={initialValues.premium} type={"currency"} />
                                                                {/* <legend className='text-bold lenged-view'> Cash Flow </legend>
                                                            <ViewDiv fieldLabel={"Total Income($)"} fieldValue={initialValues.totalIncome} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Total Expense($)"} fieldValue={initialValues.totalExpense} type={"currency"} /> */}


                                                            </>}
                                                            <legend className='text-bold lenged-view'> Additional Information</legend>
                                                            <ViewDiv fieldLabel={"Exit Strategy/Payout Details"} fieldValue={initialValues.exit_strategy} type={"text-area"} />
                                                            {/* {!printMode && <>
                                                                <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                <div className="mb-2">
                                                                    <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                </div>
                                                                <legend className="text-bold lenged-view">Capital Calls</legend>
                                                                <div className="mb-2">
                                                                    <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                                </div>
                                                            </>} */}
                                                        </div>
                                                    </div>}

                                                    {(viewMode) ? <>
                                                        <div className='col-12 only-visible-in-print'>
                                                            <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </div>
                                                        <div className='col-12 only-visible-in-print'>
                                                            <legend className="text-bold lenged-view">Capital Calls</legend>
                                                            <div className="mb-2">
                                                                <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                            </div>
                                                        </div>
                                                    </> : ''}
                                                    {(viewMode && !printMode) ? <>
                                                        <div className='col-12'>
                                                            <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </div>
                                                        <div className='col-8'>
                                                            <legend className="text-bold lenged-view">Capital Calls</legend>
                                                            <div className="mb-2">
                                                                <CapitalCalls viewAction={viewMode} capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                            </div>
                                                        </div>
                                                    </> : ''}
                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Investment Details </legend>


                                                            <FormField viewMode={viewMode} fieldName="lender_name" label="Lender Name" value={initialValues.lender_name} maxLength={200} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="loan_number" label="Loan Number" value={initialValues.loan_number} maxLength={200} isRequired={false} />

                                                            <DatePickerField viewMode={viewMode} fieldName="investment_date" label="Investment Date" value={initialValues.investment_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />

                                                            <CurrencyField viewMode={viewMode} fieldName="amount_invested" label="Amount Invested" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.investmentAuditLog ? true : false} auditValue={initialValues.investmentAuditLog} />
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Mortgage/Bank Debt" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="contingent_liability" label="Contingent Liability(%)" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} prefix=''/>

                                                                </div>

                                                            </div>

                                                            <CurrencyField viewMode={viewMode} fieldName="current_value" label="Current Valuation" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.auditLog ? true : false} auditValue={initialValues.auditLog} />

                                                            <CurrencyField viewMode={viewMode} fieldName="ownership_percentage" label="Ownership Percentage(%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                            <TextAreaField viewMode={viewMode} fieldName="class_a_members" label="Class A Members" value={initialValues.class_a_members} maxLength={200} isRequired={false} />

                                                            <TextAreaField viewMode={viewMode} fieldName="cash_distributions" label="Cash Distributions" value={initialValues.cash_distributions} maxLength={200} isRequired={false} />

                                                        </fieldset>

                                                        <fieldset>
                                                            <legend className='text-bold'> Insurance Information </legend>


                                                            <FormField viewMode={viewMode} fieldName="carrier" label="Carrier" value={initialValues.carrier} maxLength={200} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="policy_number" label="Policy Number" value={initialValues.policy_number} maxLength={200} isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="amount_insured" label="Amount Insured" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                            <DatePickerField viewMode={viewMode} fieldName="expiration_date" label="Expiration Date" value={initialValues.expiration_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="premium" label="Premium" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                        </fieldset>
                                                    </div>}
                                                    {!viewMode && <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                                                        <fieldset>
                                                            <legend className="text-bold">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                                                        <fieldset>
                                                            <legend className="text-bold">Capital Calls</legend>
                                                            <div className="mb-2">
                                                                <CapitalCalls capitalList={capitalList} CollectedCapital={handleCollectedCapital} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className='col-lg-12'>
                                                        {viewMode && <SystemInfo
                                                            createdAt={initialValues.createdAt}
                                                            updatedAt={initialValues.updatedAt}
                                                            createdBy={initialValues['investment_headers_created_by.full_name']}
                                                            updatedBy={initialValues['investment_headers_modified_by.full_name']}
                                                        />}
                                                    </div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            gap: '10px'
                                                        }}>
                                                            {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger></>}
                                                            {!viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}
                                                            {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                            {editMode != true && (!viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {viewMode && userPermissions?.length && <TabComponent investmentId={initialValues?.id} permissions={userPermissions} viewAction={viewAction} isArchived={isArchivedView}
                                uploadPermission={checkPermission(userPermissions, 'upload-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')}
                                hangleValueChange={hangleValueChange} incExptab={true} documentTypeKey={'Real Estate Holdings'} printMode={printMode} onDataLoadingComplete={onTabLoadingComplete} />}
                        </div>
                        {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />}
                    </div>
                    )}
                </Formik >
                }
            </div>
            {/* <ReminderTaskModel show={showReminderTask} handleClose={() => setShowReminderTask(false)} investmentID={investmentID} handelListUpdate={() => getReminderTaskCount()} />
            <ReminderList show={showReminderModel} recordId={investmentID} handleClose={setShowReminderModel} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={getReminderTaskCount} /> */}
            <ReminderTab show={showReminderTask} recordId={investmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={getReminderTaskCount} invesmentCatId={category_id}
                entityType={'investment'} isAdminOrManager={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager'))} actionPermission={(checkPermission(userPermissions, 'edit-real-estate-hld'))} />

        </>
    );
}
export default AddRealEstateHoldings;