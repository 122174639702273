import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_REAL_ESTATE_HOLDINGS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID } from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function RealEstateHoldingDetails() {
  const [realEstateHoldingsList, setRealEstateHoldingsList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const [userPermissions, setUserPermissions] = useState("");
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [selectedInvID, SetSelectedInvID] = useState('')
  const [enablePrint, SetEnablePrint] = useState(false)
  const { setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [showAction, setShowAction] = useState(false);
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const category_id = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;

  // Reminder Task 
  const [showReminderTask, setShowReminderTask] = useState(false);
  const [getInvestmentID, setInvestmentID] = useState('');
  const reminderTaskPopUp = (investment_id, actionPermission) => {
    setInvestmentID(investment_id);
    setReminderActionPermission(actionPermission)
    setShowReminderTask(true);
  };

  const [headerValues, setHeaderValues] = useState({
    totalInvestments: 0,
    totalCurrentValues: 0,
    totalDebt: 0,
  });

  const handleClose = () => setShowReminderTask(false);

  const handelListUpdate = () => fetchInvestmentList();

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [])

  const fetchInvestmentList = async () => {
    try {
      const getInvestment = await get(GET_REAL_ESTATE_HOLDINGS);
      const realEstateHolding = getInvestment.investments;
      const getInvestmentTotal = getInvestment.totals;
      if (realEstateHolding) {
        let isAction = realEstateHolding.some((inv) => inv.permission_keys?.length > 1);

        const filterData = realEstateHolding.filter(value => value.cal_current_value != null)

        /** Header total calculation */
        let total_value_after_Debt = 0;

        const dataFilter = filterData.map((value) => {

          let currentValue = parseFloat(value['cal_current_value']) || 0;
          total_value_after_Debt += currentValue;
          return value;
        });
        total_value_after_Debt = total_value_after_Debt.toFixed(2);



        setShowAction(isAction);
        setRealEstateHoldingsList(filterData);
        setSelectedInvList(realEstateHolding)
        setHeaderValues({
          totalInvestments: getInvestmentTotal.total_investment,
          totalCurrentValues: getInvestmentTotal.total_current_value,
          totalDebt: total_value_after_Debt,
        })
      }
      setLoading(false);
      refreshCount();
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  };

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    if (checkPermission(tempPermission, 'view-real-estate-hld') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  }

  const handleChartChange = (selectedValue) => {
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(realEstateHoldingsList)
      setSelectedChartValue("")
    } else {
      let filterInvesmentList = realEstateHoldingsList.filter((item) => (selectedValue == item['property_type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  }

  const handleDelete = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));

      if (deleteInvestment) {
        fetchInvestmentList()
      }
      notify(`${investment_name} successfully deleted`, 'success')
    } catch (error) {
      notify(`Unable to delete ${investment_name}`, 'warning')
      console.log('Error while deleteing the error : ', error)
    }

  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-real-estate-holdings/${investmentId}`);
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-real-estate-holdings/${investmentId}`);
  };

  const AddRealEstateHoldings = () => {
    setLoading(true);
    history('/add-real-estate-holdings', { state: { previousPage: "Real Estate Holdings List", previousUrl: "/real-estate-holding-list" } });
  };

  const deleteRealEstate = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete Real Estate Holdings',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const archivedRealEstate = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive  Real Estate Holdings',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleteing the error : ', error)
      notify(`Unable to archive ${investment_name}.`, 'warning',)
    }

  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedRealEstate(id, name);
        break;
      case 'delete':
        deleteRealEstate(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;
      case 'setreminder':
        handleReminderClick(id, name);
        break;
      default:
        break;
    }
  }

  const handleReminderClick = (id, name) => {
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
  };

  const handleReminderModelClose = () => {
    setShowReminderModel(false);
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Legal Entity Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      wordWarp: true, hMinWidth: '170px', hMaxWidth: "160px", action: true, order: 'asc'
    },
    { dataField: 'property_type', text: 'Property Type', hMinWidth: '120px', hMaxWidth: "140px", sort: true },
    // { dataField: 'lender_name', text: 'Lender Name', hMinWidth: '10%', hMaxWidth: "12%", sort: true },
    { dataField: 'property_address', text: 'Address', hMinWidth: '180px', hMaxWidth: "200px", sort: true, wordWarpDot: true },
    { dataField: 'building_square_feet', text: 'Building Square Feet', sort: true, hMinWidth: '160px', hMaxWidth: "170px", },
    { dataField: 'parcel_size_acreage', text: 'Acreage', sort: true, hMinWidth: '100px', hMaxWidth: "120px" },
    { dataField: 'amount_invested', text: 'Total Invested($)', sort: true, isAmountField: true, hMinWidth: '135px', hMaxWidth: "140px" },
    { dataField: 'current_valuation', text: 'Current Valuation($)', sort: true, isAmountField: true, hMinWidth: '160px', hMaxWidth: "120px" },
    { dataField: 'cal_current_value', text: 'Inv. Value after Debt ($)', sort: true, isAmountField: true, hMinWidth: '190px', hMaxWidth: "210px" },
    { dataField: 'performance', text: 'Percentage of Total', sort: false, isPerformanceField: true, hMinWidth: '160px', hMaxWidth: "12px" },
    { dataField: 'projected_annual_income', text: 'Cash Flow($)', sort: true, hMinWidth: '120px', isAmountField: true }
  ];
  let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, handleOnClick: handleOnClickAction, hMinWidth: '150px', hMaxWidth: "20px", tooltipSuffixText: " ", onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }
  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else {
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true }
    columns.push(tempAction);
  };

  const headerTotalValues = [
    { label: 'Total Invested ($)', value: headerValues.totalInvestments },
    { label: 'Total Current Valuation ($)', value: headerValues.totalCurrentValues },
    { label: 'Total Inv.Val after Debt ($)', value: headerValues.totalDebt },
  ];
  const handleBackClick = () => {
    history('/portfolio-summary');
  };

  const updateReminderData = async () => {
    await fetchInvestmentList();
  }

  const handleTableChange = (type, value = false) => {
    try {
      switch (type) {
        case 'print':
          SetEnablePrint(false);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => { SetEnablePrint(true); setLoading(true); }
        },
      ]
    });
  }

  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
          <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Real Estate Holdings</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                  </a></li>
                  <li className="breadcrumb-item active" >Real Estate Holdings</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <DataChart
              header={"Real Estate Holdings"} data={realEstateHoldingsList} labelKey={'property_type'}
              seriesKey={["cal_current_value", "amount_invested"]} onChange={handleChartChange}
              seriesName={["Current Investment Value", "Total Purchase Price"]} type="bar"
            />
            <br />

            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div className='row' >
                      <div className='col-6'><h4 style={{ flex: 'auto' }}>Real Estate Holdings List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                      </OverlayTrigger>
                      </h4></div>
                      <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                          (checkPermission(userPermissions, 'create-real-estate-hld') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                          <button type="button" onClick={AddRealEstateHoldings} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                        }
                      </div>
                    </div>
                  </div>
                  <div >
                    <Datatable data={selectedInvList} columns={columns} tableWidth={selectedInvList ? '100%' : '100%'} csvFileName={'RealEstateHoldingDetails'} actionPermissionPrefix={'real-estate-hld'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} FreezeColumn={true} headerName={'Real Estate Holdings List'} enablePrint={enablePrint} handleTableChange={handleTableChange} headerObjValue={headerTotalValues} showHeaderTotal={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ReminderTaskModel show={showReminderTask} handleClose={handleClose} investmentID={getInvestmentID} handelListUpdate={handelListUpdate} />
      <ReminderList show={showReminderModel} recordId={selectedInvID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={handelListUpdate}/> */}
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={category_id}
        entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission} />
      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default RealEstateHoldingDetails;