
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../../components/Footer";
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../Context/PageLoader';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../../api'
import {
    CREATE_INVESTMENT, GET_INVESTMENT_BY_ID, UPDATE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID,
    DELETE_INVESTMENT_BY_ID, GET_REMINDER_LIST
} from '../../../api/endpoints'
import { ActionIcon, addReminderLabel, checkPermission, concatUrlWithId, formatDateAndTime, ReminderTaskIcon, TitleTrim } from '../../../utils/commonUtils';
import { FormField, SelectField, CurrencyField, TextAreaField, DatePickerField, SystemInfo, ViewDiv } from '../../../components/Forms/FormFields';
import TabComponent from '../../../components/TabComponent';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ProfessionalContacts from '../../../components/ProfessionalContacts';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { useReactToPrint } from 'react-to-print';


function AddStock({ props }) {
    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        stock_name: '',
        no_of_shares: '',
        stock_account_number: '',
        investment_name: '',
        is_active: true,
        asset_type: '',
        account_type: '',
        amount_borrowed: '0.00',
        amount_invested: '',
        investment_category: process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID,
        investment_value: '',
        current_value: '',
        first_activity_date: '',
        broker: '',
        holding_company: '',
        interest_earned: '0.00',
        interest_earning_rate: '',
        fixed_income: false,
        note: '',
        is_active: '',
        is_archived: '',
        auditLog: false,
        maturity_date: '',
        promissory_note: '',
        // bank_account: '',
        // reminder_status: false,
        // document_reminder: false
        contingent_liability: '',
        ownership_percentage: '',
    });

    const [editMode, setEditMode] = useState(false);
    const [auditData, setAuditData] = useState([]);
    const [listEditMode, setListEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedAssetType, setIsSelectedAssetType] = useState('');
    const [updatedSchema, setIUpdatedSchema] = useState();
    // const [reminderArr, setReminderArr] = useState({ record_rem_arr: [], document_rem_arr: [] });
    const [reminderDeletedIds, setReminderDeletedIds] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [printMode, setPrintMode] = useState(false);

    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTab, setShowReminderTab] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const category_id = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const [showErrorIcon, setShowErrorIcon] = useState(false);

    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-liquid-asset/${investmentID}`, { state: { previousPage: "Liquid Asset View", previousUrl: `/view-liquid-asset/${investmentID}` } });
    }

    function deleteStock(id, Name) {
        confirmAlert({
            title: 'Delete Liquid Asset',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    const handleBackClick = (investmentId, pageUrl) => {
        if (pageUrl) {
            history(pageUrl);
        }
        else {
            history('/liquid-asset-list');
        }
    };

    const handleDelete = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));
            if (deleteInvestment) {
                history('/liquid-asset-list')
            }
            notify(`${investment_name} successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
        }
    };


    const archivedStock = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive Liquid Asset',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true })

            if (archive) {
                history('/liquid-asset-list')
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while deleteing the error : ', error)
        }
    };

    const notify = (message, type) => toast(message, { type });

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };

    const onChangeInterest = async (value) => {
        setInitialValues({ ...initialValues, interest_earned: value })
    }

    useEffect(() => {
        // window.scrollTo(0, 0);
        authCheckAndUpdate();
        if (investmentID) {
            getReminderTaskCount()
        }

        if (urlName == 'view-liquid-asset') {
            setViewMode(true)
            setEditMode(false);
            setViewAction(true);
            setPreviousPage('Liquid Asset List')
            setPreviousUrl('/liquid-asset-list')

        } else if (urlName == 'edit-liquid-asset') {
            setViewMode(false)
            setEditMode(true)
            setViewAction(false)
            setPreviousPage('Liquid Asset List')
            setPreviousUrl('/liquid-asset-list')
        }

        const fetchInvestmentById = async () => {
            await authCheckAndUpdate();
            if (location.state) {
                setListEditMode(location.state.listEditMode)
                setPreviousPage(location.state.previousPage)
                setPreviousUrl(location.state.previousUrl)
            } else {
                setLoading(false);
            }
            if ((urlName == 'edit-liquid-asset') || (urlName == 'view-liquid-asset')) {
                try {
                    setLoading(true);
                    const response = await get(concatUrlWithId(GET_INVESTMENT_BY_ID, investmentID));
                    if (response) {
                        setAuditData(response.auditLogs)
                        let values = {
                            ...response,
                            first_activity_date: formatDateAndTime(response['stock_investment_details.first_activity_date'], "YYYY-MM-DD"),
                            current_value_updated_date: moment(response.current_value_updated_date).format('MM/DD/YYYY'),
                            investment_id: response.id,
                            asset_type: response['stock_investment_details.asset_type'],
                            stock_name: response['stock_investment_details.stock_name'],
                            stock_account_number: response['stock_investment_details.stock_account_number'],
                            no_of_shares: response['stock_investment_details.no_of_shares'],
                            investment_value: response['stock_investment_details.investment_value'],
                            amount_invested: response.amount_invested,
                            current_value: response.current_value,
                            interest_earning_rate: response['stock_investment_details.interest_earning_rate'],
                            interest_earned: response['stock_investment_details.interest_earned'],
                            broker: response['stock_investment_details.broker'],
                            holding_company: response['stock_investment_details.holding_company'],
                            performance: response['stock_investment_details.performance'],
                            investment_detail_id: response['stock_investment_details.id'],
                            auditLog: response?.investmentAuditLog?.investment_headers?.current_value,
                            investmentAuditLog: response?.investmentAuditLog?.investment_headers?.amount_invested,
                            maturity_date: moment(response['stock_investment_details.maturity_date']).format('YYYY-MM-DD'),
                            account_type: response['stock_investment_details.account_type'],
                            promissory_note: response['stock_investment_details.promissory_note'] || "",
                            // bank_account: response['stock_investment_details.bank_account'],
                            // record_reminder: response['record_reminder'],
                            // document_reminder: response['document_reminder']
                            ownership_percentage: response['stock_investment_details.ownership_percentage'],
                        };

                        // let tempRecRemArr = addReminderLabel(response['record_reminder_arr'])
                        // let tempDocRemArr = addReminderLabel(response['document_reminder_arr'])
                        // setReminderArr({ record_rem_arr: tempRecRemArr, document_rem_arr: tempDocRemArr })

                        setContacts(response.professional_contacts)
                        setIsSelectedAssetType(response['stock_investment_details.asset_type']);
                        if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                            setUserPermissions(response.permission_keys[0].permission_keys)
                        }

                        setInitialValues(values);
                        if (response.is_archived == true) {
                            setViewAction(false)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    }
                } catch (error) {
                    window.scrollTo(0, 1);
                    console.log('Error while throwing:: ', error);
                    notify(error?.response?.data?.message, 'error');
                } finally {
                    window.scrollTo(0, 1);
                    // window.scrollTo(0, 0);
                    setLoading(false);
                }
            } else {
                window.scrollTo(0, 1);
                // window.scrollTo(0, 0);
                setLoading(false);
            }
        }
        fetchInvestmentById();

    }, [urlName, investmentID]);

    const assetTypes = [
        { value: 'Bonds Treasuries and CDs', label: 'Bonds, Treasuries and CDs' },
        { value: 'Cash Account', label: 'Cash Account' },
        { value: 'Company Stock', label: 'Company Stock' },
        { value: 'ETFs', label: 'ETFs' },
        { value: 'Index Funds', label: 'Index Funds' },
        { value: 'Mutual Funds', label: 'Mutual Funds' },
        { value: 'Operating Business Accounts', label: 'Operating Business Accounts' },
        { value: 'Private Equity Accounts', label: 'Private Equity Accounts' },
        { value: 'Real Estate Holdings Accounts', label: 'Real Estate Holdings Accounts' },
        { value: 'Royalty Income Accounts', label: 'Royalty Income Accounts' }
    ];
    const accountTypes = [
        { value: 'Certificate of Deposit', label: 'Certificate of Deposit' },
        { value: 'Checking', label: 'Checking' },
        { value: 'Money Market', label: 'Money Market' },
        { value: 'Savings', label: 'Savings' },
    ];

    const handleAsset_typeChange = (value) => {
        investmentSchema(value)
        setIsSelectedAssetType(value);
    };

    // Reminder change start
    const handleReminderChange = (value) => { };

    const handleCollectedContacts = (updatedContacts) => {
        setContacts(updatedContacts);
    };

    const handleReminderModelClose = () => {
        setShowReminderModel(false);
    };

    const handelListUpdate = () => {
    }

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: investmentID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }

    const handleBeforePrint = () => {
        setPrintMode(true);
    };

    const handleAfterPrint = () => {
        setLoading(false);
        setPrintMode(false);
    };

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.investment_name || 'investment'
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }

    // Reminder change end 
    const investmentSchema = (assetType) => {
        let schema = {
            investment_name: Yup.string().label('Investment Name').trim().required('Investment Name is required'),
            investment_category: Yup.string().label('Investment Category').required('Investment Category is required'),
            fixed_income: Yup.boolean().label('Fixed Income'),
            amount_invested: Yup.string()
                .nullable()
                .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
            amount_borrowed: Yup.string()
                .nullable()
                .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
            asset_type: Yup.string().label('Asset Type').required('Asset Type is required'),
            account_type: Yup.string().label('Account Type'),
            stock_name: Yup.string().label('Stock Symbol').trim(),
            stock_account_number: Yup.string().label('Account Number').trim(),
            no_of_shares: Yup.string()
                .nullable()
                .matches(/^\d{1,16}$/, 'Use up to 16 digits.'),
            first_activity_date: Yup.date().label('First Activity Date'),
            current_value: Yup.string().required('Current Value is required')
                .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
            // interest_earned: Yup.string()
            //     .nullable()
            //     .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.'),
            broker: Yup.string().label('Broker').trim(),
            holding_company: Yup.string().label('Holding Company').trim(),
            // bank_account: Yup.string().label('Bank Account').trim(),
        };

        if (selectedAssetType == "Company Stock") {
            schema.stock_name = schema.stock_name.required('Stock Symbol is required')
            schema.stock_account_number = schema.stock_account_number.required('Account Number is required')
            schema.no_of_shares = schema.no_of_shares.required('Number of Shares is required')
            schema.first_activity_date = schema.first_activity_date.required('First Activity Date is required')
            schema.amount_invested = schema.amount_invested.required('Amount Invested is required')
        } else if (selectedAssetType == "Bonds Treasuries and CDs") {
            schema.stock_account_number = schema.stock_account_number.required('Account Number is required')
            schema.amount_invested = schema.amount_invested.required('Amount Invested is required')
            schema.first_activity_date = schema.first_activity_date.required('First Activity Date is required')
            schema.maturity_date = Yup.date().label('Maturity Date').required('Maturity Date is required')

        } else if (selectedAssetType == "Cash Account") {
            schema.stock_account_number = schema.stock_account_number.required('Account Number is required')
            schema.first_activity_date = schema.first_activity_date.required('First Activity Date is required')
            schema.holding_company = schema.holding_company.required('Holding Company is required')
            schema.account_type = schema.account_type.required('Account Type is required')
            schema.amount_invested = schema.amount_invested.required('Amount Invested is required')
        } else if (selectedAssetType == "Mutual Funds" || selectedAssetType == "Index Funds" || selectedAssetType == "ETFs") {
            schema.amount_invested = schema.amount_invested.required('Amount Invested is required')
            schema.stock_account_number = schema.stock_account_number.required('Account Number is required')
            schema.first_activity_date = schema.first_activity_date.required('First Activity Date is required')
            schema.holding_company = schema.holding_company.required('Holding Company is required')
            // schema.account_type = schema.account_type.required('Account Type is required')
        }
        return Yup.object().shape(schema);
    };


    const handleClose = () => {
        // console.log('this handle close from the add stock');
    }

    const updateReminderData = async () => {
        await getReminderTaskCount()
    }

    const onDataLoadingComplete = () => {
        setTimeout(() => {
            handlePrint()
        }, 100);
    }
    const checkValidation = () => {
        setShowErrorIcon(true);
    }

    return (
        <>
            <div className="container-fluid" style={{ marginTop: '20px' }}>
                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix"  >
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : 'Add New')} `} <TitleTrim title={initialValues.investment_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Liquid Asset' : (viewMode ? 'View Liquid Asset' : 'Add Liquid Asset')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
            <div ref={formRef}>

                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={investmentSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        try {

                            let response = []
                            const url = editMode ? concatUrlWithId(UPDATE_INVESTMENT_BY_ID, investmentID) : CREATE_INVESTMENT;
                            const bodyPayload = {
                                stock_name: values.stock_name,
                                no_of_shares: values.no_of_shares,
                                stock_account_number: values.stock_account_number,
                                investment_name: values.investment_name,
                                asset_type: values.asset_type,
                                amount_borrowed: values.amount_borrowed,
                                amount_invested: values.amount_invested,
                                investment_category: values.investment_category,
                                investment_value: values.amount_invested,
                                current_value: values.current_value,
                                first_activity_date: values.first_activity_date,
                                broker: values.broker,
                                holding_company: values.holding_company,
                                interest_earning_rate: values.interest_earning_rate,
                                interest_earned: initialValues.interest_earned,
                                note: values.note,
                                fixed_income: values.fixed_income,
                                account_type: values.account_type,
                                maturity_date: values.maturity_date,
                                promissory_note: values.promissory_note,
                                professional_contacts: contacts,
                                contingent_liability: values.contingent_liability,
                                ownership_percentage: values.ownership_percentage,
                            };

                            response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                            if (response) {
                                notify(`Liquid Asset ${values.investment_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setContacts([]);
                                    setShowErrorIcon(false);
                                } else {
                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/liquid-asset-list', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }

                            } else {
                                editMode ? notify(`Liquid Asset update failed...`, 'error') : notify(`Create Liquid Asset failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');

                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                    }}
                >
                    {props => (<div className="main-content introduction-farm">
                        <div className="content-wraper-area ">
                            <div className="dashboard-area">
                                <div className="container-fluid ">
                                    <div className="card">
                                        <div className={`card-body ${viewMode ? 'view-mode bg-white' : 'add-mode'}`}>
                                            {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            }
                                            {viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                        {/* <i className="fa fa-print btn-circle print-btn" onClick={() => { onDataLoadingComplete() }} ></i> */}
                                                    </OverlayTrigger>
                                                    <ReminderTaskIcon
                                                        iconClass="no-print "
                                                        tooltip={`Set/View Reminders`}
                                                        onClick={() => setShowReminderTab(true)}
                                                        backgroundColor="#8098ad"
                                                        count={reminderTaskCount}
                                                    />
                                                    {(checkPermission(userPermissions, 'edit-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>} >
                                                        <i className="bx bxs-edit btn btn-circle" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'archive-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive</Tooltip>} >
                                                        <i className="zmdi zmdi-archive btn btn-circle" onClick={() => archivedStock(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'delete-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>} >
                                                        <i className="bx bx-trash btn btn-circle" onClick={() => deleteStock(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                    </OverlayTrigger>}
                                                </div>
                                            </div>
                                            }
                                            <Form>
                                                {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                        <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Liquid Asset - {initialValues.investment_name}</h4>
                                                    </div>
                                                </div>}
                                                <div className='row print-font-header'>
                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold '> Liquid Asset Information </legend>

                                                            <FormField viewMode={viewMode} fieldName="investment_name" label="Investment Name" value={initialValues.investment_name} maxLength={200} />
                                                            <SelectField viewMode={viewMode} fieldName="asset_type" label="Asset Type" value={initialValues.asset_type} options={assetTypes} values={props.values} isRequired={true} setFieldValue={(field, val) => {
                                                                props.setFieldValue('asset_type', val);
                                                                handleAsset_typeChange(val);
                                                            }} />
                                                            <FormField viewMode={viewMode} fieldName="stock_name" label="Stock Symbol" isRequired={(selectedAssetType == 'Company Stock') ? true : false} value={initialValues.stock_name} maxLength={100} />
                                                            {/* <FormField viewMode={viewMode} fieldName="bank_account" label="Bank Account" isRequired={false} value={initialValues.bank_account} maxLength={100} /> */}
                                                            <FormField viewMode={viewMode} fieldName="stock_account_number" label="Account Number" isRequired={(selectedAssetType != '') ? true : false} value={initialValues.stock_account_number} maxLength={100} />
                                                            <FormField viewMode={viewMode} fieldName="no_of_shares" label="No of Shares" isRequired={(selectedAssetType == 'Company Stock') ? true : false} value={initialValues.no_of_shares} maxLength={16} type="number" />
                                                            {selectedAssetType == 'Cash Account' && <SelectField viewMode={viewMode} fieldName="account_type" label="Account Type" value={initialValues.account_type} options={accountTypes} values={props.values} isRequired={(selectedAssetType != '' && selectedAssetType == 'Cash Account') ? true : false} setFieldValue={props.setFieldValue} />}
                                                            <DatePickerField viewMode={viewMode} fieldName="first_activity_date" label="First Activity Date" isRequired={(selectedAssetType != '') ? true : false} value={initialValues.first_activity_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />
                                                            {selectedAssetType == 'Bonds Treasuries and CDs' && <DatePickerField viewMode={viewMode} fieldName="maturity_date" label="Maturity Date" isRequired={(selectedAssetType != '' && selectedAssetType == 'Bonds Treasuries and CDs') ? true : false} value={initialValues.first_activity_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} />}
                                                            <TextAreaField viewMode={viewMode} fieldName="promissory_note" label="Promissory Note" value={initialValues.promissory_note} maxLength={1000} isRequired={false} />
                                                        </fieldset>
                                                    </div>}

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Liquid Asset Information </legend>
                                                            <ViewDiv fieldLabel={"Investment Name"} fieldValue={initialValues.investment_name} type={"text"} />
                                                            <ViewDiv fieldLabel={"Asset Type"} fieldValue={initialValues.asset_type} type={"text"} />
                                                            <ViewDiv fieldLabel={"Stock Symbol"} fieldValue={initialValues.stock_name} type={"text"} />
                                                            {/* <ViewDiv fieldLabel={"Bank Account"} fieldValue={initialValues.bank_account} type={"text"} /> */}
                                                            <ViewDiv fieldLabel={"Account Number"} fieldValue={initialValues.stock_account_number} type={"text"} />
                                                            <ViewDiv fieldLabel={"No of Shares"} fieldValue={initialValues.no_of_shares} type={"text"} />
                                                            {selectedAssetType == 'Cash Account' && <ViewDiv fieldLabel={"Account Type"} fieldValue={initialValues.account_type} type={"text"} />}
                                                            <ViewDiv fieldLabel={"First Activity Date"} fieldValue={initialValues.first_activity_date} type={"date"} />
                                                            {selectedAssetType == 'Bonds Treasuries and CDs' && <ViewDiv fieldLabel={"Maturity Date"} fieldValue={initialValues.maturity_date} type={"date"} />}

                                                            <legend className='text-bold lenged-view'> System Information</legend>
                                                            <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['investment_headers_created_by.full_name']} type={"text"} />
                                                            <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['investment_headers_modified_by.full_name']} type={"text"} />
                                                        </div>
                                                    </div>}

                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold '> Financial Entities </legend>
                                                            <FormField viewMode={viewMode} fieldName="broker" label="Broker" value={initialValues.broker} maxLength={100} isRequired={false} />
                                                            <FormField viewMode={viewMode} fieldName="holding_company" label="Holding Company" isRequired={(selectedAssetType != 'Company Stock' && selectedAssetType != 'Bonds Treasuries and CDs' && selectedAssetType != '') ? true : false} value={initialValues.holding_company} maxLength={100} />

                                                        </fieldset>

                                                        <fieldset>
                                                            <legend className='text-bold'> Investment Details </legend>
                                                            <CurrencyField viewMode={viewMode} fieldName="amount_invested" label="Amount Invested" isRequired={(selectedAssetType != '') ? true : false} values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.investmentAuditLog ? true : false} auditValue={initialValues.investmentAuditLog} />
                                                            <CurrencyField viewMode={viewMode} fieldName="current_value" label="Current Value" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.auditLog ? true : false} auditValue={initialValues.auditLog} />
                                                            {/* <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Amount Borrowed" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} /> */}
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Amount Borrowed" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <CurrencyField viewMode={viewMode} fieldName="contingent_liability" label="Contingent Liability(%)" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} prefix='' />

                                                                </div>

                                                            </div>
                                                            <CurrencyField viewMode={viewMode} fieldName="interest_earning_rate" label="Interest Earning Rate (%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />
                                                            {/* <CurrencyField viewMode={viewMode} fieldName="interest_earned" label="Interest Earned" values={initialValues} setFieldValue={props.setFieldValue} isRequired={false} disabled={true} /> */}
                                                            <CurrencyField viewMode={viewMode} fieldName="ownership_percentage" label="Ownership Percentage (%)" values={props.values} setFieldValue={props.setFieldValue} prefix={''} isRequired={false} disableNegative={true} />

                                                            {/* <SwitchField fieldName="fixed_income" label="Fixed Income" viewMode={viewMode} values={props.values} setFieldValue={props.setFieldValue} /> */}
                                                        </fieldset>


                                                    </div>}
                                                    {
                                                        !viewMode && <div className="col-lg-12 col-md-12 col-12 ">
                                                            <fieldset>
                                                                <legend className='text-bold'> Additional Information</legend>
                                                                <TextAreaField viewMode={viewMode} fieldName="note" label="Notes" value={initialValues.note} maxLength={500} />
                                                            </fieldset>
                                                        </div>
                                                    }

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Financial Entities  </legend>
                                                            <ViewDiv fieldLabel={"Broker"} fieldValue={initialValues.broker} type={"text"} />
                                                            <ViewDiv fieldLabel={"Holding Company"} fieldValue={initialValues.holding_company} type={"text"} />

                                                            <legend className='text-bold lenged-view'> Investment Details  </legend>
                                                            <ViewDiv fieldLabel={"Amount Invested($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Current Value($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Amount Borrowed($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} extra={true} depValue={initialValues.contingent_liability} />
                                                            <ViewDiv fieldLabel={"Interest Earning Rate(%)"} fieldValue={initialValues.interest_earning_rate} type={"text"} />
                                                            <ViewDiv fieldLabel={"Ownership Percentage(%)"} fieldValue={initialValues.ownership_percentage} type={"text"} />
                                                            <ViewDiv fieldLabel={"Interest Earned($)"} fieldValue={initialValues.interest_earned} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Promissory Note"} fieldValue={initialValues.promissory_note} type={"text-area"} />

                                                            <legend className='text-bold lenged-view'> Additional Information  </legend>
                                                            <ViewDiv fieldLabel={"Notes"} fieldValue={initialValues.note} type={"text-area"} />

                                                            {/* {!printMode && <>
                                                                <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                <div className="mb-2">
                                                                    <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                </div>
                                                            </>} */}

                                                        </div>
                                                    </div>}
                                                    {(viewMode) && <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                        <div >
                                                            <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {(!printMode && !viewMode) && <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                                                        <fieldset>
                                                            <legend className="text-bold">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            gap: '10px'
                                                        }}>
                                                            {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger></>}
                                                            {/* {!viewMode && <button type="reset" onClick={() => history('/stock-list')} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>} */
                                                                !viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}

                                                            {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                            {editMode != true && (
                                                                !viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                        </div>
                                                    </div >
                                                </div >
                                            </Form >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div className="card-body">
                            {viewMode && userPermissions?.length && <TabComponent investmentId={initialValues?.id} stockInvId={initialValues?.investment_detail_id} permissions={userPermissions} viewAction={viewAction}
                                uploadPermission={checkPermission(userPermissions, 'upload-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')}
                                isArchived={!viewAction} isStockView={true} onChangeInterest={onChangeInterest} documentTypeKey={'Liquid Assets'} printMode={printMode} onDataLoadingComplete={onDataLoadingComplete} />}
                        </div>
                        {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />}
                    </div >
                    )
                    }
                </Formik >
                }
            </div >
            <ReminderTab show={showReminderTab} recordId={investmentID} handleClose={() => setShowReminderTab(false)} hangleTabChange={getReminderTaskCount}
                invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager'))} actionPermission={(checkPermission(userPermissions, 'edit-stock'))} />
        </>
    );
}
export default AddStock;