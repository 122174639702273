import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../components/Footer";
import { PageLoader } from '../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Datatable from '../components/Table/Datatable';
import DataChart from '../components/DataChart';
import { get } from '../api';
import { GET_OVERVIEW_DATA } from '../api/endpoints'
import { checkPermission } from '../utils/commonUtils';
import { UserPermissionStore } from '../Store/UserPermission';
import PieChart from '../components/PieChart';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function Overview() {
  const [overviewDataList, setOverViewList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [projectedIncomeData, setProjectedIncomeData] = useState([]);
  const [filterOptions, setFilterOptions] = useState("");
  const { loading, setLoading } = useContext(PageLoader);
  const [userPermissions, setUserPermissions] = useState([]);
  const navigation = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [enablePrint, SetEnablePrint] = useState(false)

  const fetchInvestmentList = async () => {
    try {
      setLoading(true)
      const overviewData = await get(GET_OVERVIEW_DATA);

      if (overviewData?.investmentData?.length) {
        let overviewInvData = overviewData.investmentData
        const filterData = overviewInvData.filter(value => value.current_value != null);
        setOverViewList(filterData);
        setSelectedInvList(overviewInvData)
        const dropdownOptionsSet = new Set();
        overviewInvData.forEach(item => {
          const value = item['investment_categories.value'];
          if (value !== null) {
            dropdownOptionsSet.add(value);
          }
        });

        const dropdownOptions = Array.from(dropdownOptionsSet).reduce((options, value) => {
          options.push({ label: value, value });
          return options;
        }, [{ label: 'All', value: 'all' }]);
        setFilterOptions(dropdownOptions);
      };

      if (overviewData?.projectIncome && Object.keys(overviewData.projectIncome)?.length) {
        setProjectedIncomeData(overviewData.projectIncome)
      }
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  }

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    const permissionFetch = async () => {
      if (tempPermission) {
        if (!tempPermission.length) {
          setUserPermissions([]);
        } else {
          setUserPermissions(tempPermission);
        }
      }
      if (checkPermission(tempPermission, 'overview') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
        fetchInvestmentList()
      } else {
        setLoading(false);
      }
    };
    permissionFetch();
  }

  useEffect(() => {
    //Check Auth permissions and fetch the data 
    authCheckAndFetch();
  }, [])

  const handleViewClick = (type, investmentId, categoryId) => {
    const real_estate_master = process.env.REACT_APP_INVESTMENT_REAL_ESTATE_MASTER_ID;
    const stock_master = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;
    const mineral_asset_master = process.env.REACT_APP_INVESTMENT_MINERAL_ASSET_MASTER_ID;
    const personal_asset_master = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
    const real_estate_holding_master = process.env.REACT_APP_REAL_ESTATE_HOLDINGS_DETAILS_MASTER_ID;
    if (categoryId == real_estate_master) {
      setLoading(true);
      navigation(`/view-private-equity/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == stock_master) {
      setLoading(true);
      navigation(`/view-liquid-asset/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == mineral_asset_master) {
      setLoading(true);
      navigation(`/view-royalty-income/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == personal_asset_master) {
      setLoading(true);
      navigation(`/view-personal-assets/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    } else if (categoryId == real_estate_holding_master) {
      setLoading(true);
      navigation(`/view-real-estate-holdings/${investmentId}`, { state: { previousPage: "Portfolio Summary", previousUrl: "/portfolio-summary", isArchived: false } });
    }
  };

  const handleFilterChange = (selectedValue) => {
    if (selectedValue === 'all') {
      setSelectedInvList(overviewDataList)
    } else {
      let filterInvesmentList = overviewDataList.filter((item) => (selectedValue == item['investment_categories.value']));
      setSelectedInvList(filterInvesmentList)
    }
  }

  const handleChartChange = (selectedValue) => {
    if (selectedValue != '') {
      setLoading(true)
      switch (selectedValue) {
        case 'Liquid Assets':
          navigation('/liquid-asset-list');
          break;
        case 'Private Equity':
          navigation('/private-equity-list');
          break;
        case 'Real Estate Holdings':
          navigation('/real-estate-holding-list');
          break;
        case 'Royalty Income':
          navigation('/royalty-income-list');
          break;
        case 'Personal Assets':
          navigation('/personal-assets-list');
          break;
        case 'Operating Business':
          navigation('/operating-business');
          break;
        case 'Net Worth':
          navigation('/net-worth-list');
          break;
        default:
          setLoading(false);
          break;
      }
    }
  }

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case  'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    // { dataField: 'investment_name', text: 'Investment Name', sort: true, hMinWidth: '15%', hMaxWidth:"15%",  wordWarp: true },
    {
      dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleViewClick, onClickParams: ['id', 'investment_category'],
      headerWidth: '250px', wordWarp: true, action: true, order: 'asc'
    },
    { dataField: 'investment_categories.value', text: 'Asset Class', hMinWidth: '12%', hMaxWidth: "15%", sort: true },
    // { dataField: 'amount_invested', text: 'Amount Invested', sort: true, hMinWidth: '12%', hMaxWidth: "15%", isAmountField: true },
    { dataField: 'cal_current_valuation', text: 'Current Value($)', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isAmountField: true },
    // { dataField: 'performance', text: 'Performance', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isPerformanceField: true },
    { dataField: 'createdAt', text: 'Created Date', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isDateField: true },
    { dataField: 'updatedAt', text: 'Updated Date', sort: true, hMinWidth: '12%', hMaxWidth: "13%", isDateField: true },
  ];

  return (<div className="main-content introduction-farm">
    <div className="content-wraper-area">
      <div className="dashboard-area">
        <div className="container-fluid">
          <DataChart
            header={"Portfolio Summary"} data={overviewDataList} labelKey={'investment_categories.value'}
            seriesKey={["current_value", "amount_invested"]} handleDoubleClick={handleChartChange}
            seriesName={["Current Valuation", "Total Purchase Price"]} type="bar"
          />

          <br />
          <div className='row'>
            <div className='col-sm-12 col-lg-6 col-6'>
              <PieChart
                header={"Net Worth"} data={overviewDataList} labelKey={'investment_categories.value'}
                seriesKey={['current_value', 'amount_borrowed']}
                seriesName={["Current Valuation", "Total Purchase Price"]}
                handleDoubleClick={handleChartChange}
                pieChartType={'net_worth'}
              />
            </div>
            <div className='col-sm-12 col-lg-6 col-6'>
              <PieChart
                header={"Projected Annual Income"} data={projectedIncomeData} labelKey={'investment_categories.value'}
                pieChartType={'project_income'} handleDoubleClick={handleChartChange}
              />
            </div>

          </div>

          <br />
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title no-print" style={{ marginBottom: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <h4 style={{ flex: 'auto' }}>Portfolio Summary List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => {SetEnablePrint(true); setLoading(true)}} ></i>
                      </OverlayTrigger></h4>
                  </div>
                </div>
                <div >
                  <Datatable data={selectedInvList} columns={columns} csvFileName={'Portfolio Summary List'} headerName={'Portfolio Summary List'} filterOptions={filterOptions} handleFilterChange={handleFilterChange} enablePrint={enablePrint} handleTableChange={handleTableChange}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
  </div>);
}
export default Overview;