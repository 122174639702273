import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';

const PieChart = ({ header, data, pieChartType, onChange, handleDoubleClick }) => {
    const [tempSelectedChartValue, setTempSelectedChartValue] = useState("");
    const [clickEvent, setClickEvent] = useState("");
    const [chartType, setChartType] = useState('pie');
    const [totalAmount, setTotalAmount] = useState('');
    const location = useLocation();
    const [totalContingentLiability, setTotalContingentLiability] = useState('');
    const history = useNavigate();
    const headerName = header || 'chart';

    const roundAmount = (amount) => {
        if (amount >= 1000000) {
            return (amount / 1000000).toFixed(0) + 'M';
        } else if (amount >= 1000) {
            return (amount / 1000).toFixed(0) + 'K';
        } else {
            return amount;
        }
    }


    const [pieChart, setPieChart] = useState({
        series: [],
        options: {
            labels: [],
            chart: {
                type: 'pie',
                fontFamily: 'inter, sans-serif',
            },
            colors: [
                "#4ee5a6", // Green
                "#ff9f00", // Orange
                "#3e9efc", // Blue
                "#8e44ad", // Purple
                "#3498db", // Sky Blue
                "#f1c40f", // Yellow
                "#2ecc71", // Emerald
                "#7698b3", // Dark
                "#e67e22", // Carrot
                "#95a5a6", // Concrete
                "#16a085", // Green Sea
                "#9b59b6", // Amethyst 
            ],
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    const amount = opts.w.config.series[opts.seriesIndex];
                    return [`$${roundAmount(amount)}`, `${val.toFixed(2)}%`];
                },
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                    colors: ['#fff'],
                },
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 0,
                    customScale: 1,
                    dataLabels: {
                        offset: -18,
                        minAngleToShowLabel: 20
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0, // Ensures no .00 when value is an integer
                            maximumFractionDigits: 0,
                        }).format(value.toFixed(0));
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 1900,
                    options: {
                        chart: {
                            width: 500,
                            height: 300,
                            events: {
                                dataPointSelection: (event, chartContext, config) => {
                                    let selectedData = config.w.config.labels[config.dataPointIndex];
                                    selectedData = selectedData.replace(/\s*\(.*?\)\s*/, '');
                                    setTempSelectedChartValue(selectedData, data);
                                    if (event?.detail === 2) {
                                        setClickEvent(selectedData);
                                    }
                                },
                            },
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: "bottom",
                            horizontalAlign: 'center',
                            floating: false,
                            offsetY: 0,
                            fontSize: '12px'
                        },
                    },
                },
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: 200,
                            height: 400
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "30px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
                {
                    breakpoint: 400,
                    options: {
                        chart: {
                            width: 330,
                            height: 250
                        },
                        noData: {
                            text: 'No data available',
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: "20px",
                                color: "#7f838b",
                                opacity: "0.7"
                            },
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    useEffect(() => {
        if (pieChartType == 'project_income') {
            if (data && Object.keys(data)?.length) {
                let tempLabels = Object.keys(data);
                let tempTotalAmount = 0;
                let tempSeries = Object.keys(data).reduce((tempArr, key) => {
                    tempArr.push(data[key]);
                    tempTotalAmount += typeof data[key] == 'number' ? data[key] : !(isNaN(parseFloat(data[key])) ? parseFloat(data[key]) : 0)
                    return tempArr;
                }, [])
                setTotalAmount(new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(tempTotalAmount.toFixed(2)))
                setPieChart((prevStockOverView) => ({
                    ...prevStockOverView,
                    series: tempSeries,
                    options: {
                        ...prevStockOverView.options,
                        labels: tempLabels,
                    },
                }));

            }
        } else {
            let totalAssets = 0;
            let totalLiabilities = 0;
            let contingentLiability = 0;
            data.forEach((item) => {
                if (item.amount_borrowed != 'NaN') {
                    totalAssets += parseFloat(item.current_value || 0);
                    totalLiabilities += parseFloat(item.amount_borrowed || 0);
                }
                if (item.contingent_liability != 'NaN') {
                    contingentLiability += parseFloat(item.cal_contingent_liability || 0);
                }
            });

            const totalNetWorth = totalAssets - totalLiabilities;
            const tempTotalAmount = totalAssets + totalLiabilities;
            setTotalAmount(new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(tempTotalAmount.toFixed(2)))
            setTotalContingentLiability(new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(contingentLiability.toFixed(2)))
            setPieChart((prevStockOverView) => ({
                ...prevStockOverView,
                series: [totalAssets, totalLiabilities, totalNetWorth],
                options: {
                    ...prevStockOverView.options,
                    labels: ['Total Assets', 'Total Liabilities', 'Net Worth'],
                },
            }));

        }
    }, [data]);

    useEffect(() => {
        if (tempSelectedChartValue) {
            if (location.pathname != '/portfolio-summary') {
                onChange(tempSelectedChartValue)
            }
            if (clickEvent !== "" && location.pathname === '/portfolio-summary') {
                handleDoubleClick(pieChartType == 'project_income' ? clickEvent : 'Net Worth')
            }
            setTempSelectedChartValue("")
        }
    }, [tempSelectedChartValue]);

    const handleNavigate = () => {
         history('/contingent-liability-list');
    }

    return (
        <div className="row">
            <div className="col-12 mb-20 mb-2">
                <div className="card">
                    <div className="card-body">
                        <div className="single-widget d-flex align-items-center justify-content-between">
                            <div className="row" style={{ width: '100%', marginBottom: `${(pieChartType == 'project_income') ? '-15px' : ''}` }}>
                                <div className={`${(pieChartType == 'project_income') ? "col-7" : (pieChartType == 'net_worth') ? "col-5" : "col-12"}`}>
                                    <div className="card-title" style={{ marginBottom: '5px' }}>
                                        <h4>{headerName}</h4>
                                    </div>
                                </div>
                                {(pieChartType == 'project_income') ? <div className="col-5 card-title">
                                    <div style={{ textAlign: 'right', color: '#636161', fontSize: 'small' }}>
                                        <span style={{ fontWeight: 'bold' }}>Total Amount : </span>
                                        <span> &nbsp;{totalAmount} </span>
                                    </div>
                                    {/* Other content for the right side */}
                                </div> : ''}
                                {(pieChartType == 'net_worth') ? <div className="col-7 card-title">
                                    <div style={{ textAlign: 'right', color: '#636161', fontSize: 'small' }}>
                                        <span style={{ fontWeight: 'bold' }}> Contingent Liability : </span>
                                        <span onClick={ () => handleNavigate()}> &nbsp;{totalContingentLiability} </span>
                                    </div>
                                    
                                    {/* Other content for the right side */}
                                </div> : ''}
                            </div>

                        </div>
                        <div id="stock-overview" style={{ display: 'flex', justifyContent: 'center' }}>
                            {(pieChartType == 'project_income' ? Object.keys(data)?.length : data?.length) ? (
                                <Chart
                                    options={pieChart.options}
                                    series={pieChart.series}
                                    type={'pie'}
                                    height={300}
                                    width={500}
                                />
                            ) : (
                                <div className="datachart-nodata">No data available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PieChart;
