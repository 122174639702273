
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../Context/PageLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert';
import { get, remove, put } from '../../api'
import { GET_USERS_LIST, DELETE_USER, RESET_PASSWORD, USER_STATUS } from '../../api/endpoints'
import { checkPermission, concatUrlWithId } from '../../utils/commonUtils';
import Datatable from '../../components/Table/Datatable';
import UserModelForm from '../userManagement/UserModelForm';
import { UserPermissionStore } from '../../Store/UserPermission';

function UserList() {

  const history = useNavigate();
  const location = useLocation();
  const {loading, setLoading } = useContext(PageLoader);
  const [UserList, setUserList] = useState('');
  /* Upload Modal  */
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('add');

  const [editId, setEditId] = useState('add');
  const [userPermissions, setUserPermissions] = useState([]);
  const { checkAndUpdatePermissions } = UserPermissionStore();

  const handleClose = () => {
    fetchUserList();
    setShow(false);
  }
  const handleShow = (type) => {

    setShow(true);
    setMode(type)
  }
  const handleAdd = () => {
    handleShow();
  }

  const handleEdit = (id) => {
    // handleShow('edit');
    // setEditId(id);
    history(`/user-info/${id}`);
  }

  const onUploadSuccess = () => {
    handleClose()
    fetchUserList();
  }
  const notify = (message, type) => toast(message, { type });

  const fetchUserList = async () => {
    try {
      const usersList = await get(GET_USERS_LIST);
      if (usersList) {
        setUserList(usersList);
      }
    } catch (error) {
      console.log('Error while throughing:: ', error);
    } finally {
      setLoading(false);
    }
  }

  const handleOnClickAction = (type, id, name, status) => {
   
    switch (type) {
      case 'view':
        handleViewClick(id, );
        break;
      case 'edit':
        handleEdit(id);
        break;
      case 'delete':
        deleteUser(id, name);
        break;
      case 'resetPassword':
        reSetUserPassword(id, name);
        break;
      case 'statusUpdate':
        updateStatus(id, name, status);
        break;
      default:
        break;
    }
  }


  const handleViewClick = (userId) => {
    history(`/user-info/${userId}`);
  };

  const authCheckAndUpdate = async () => {
    let tempPermission =  await checkAndUpdatePermissions();
    setUserPermissions(tempPermission)
    if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchUserList();
    } else {
      history('/portfolio-summary')
    }
  };
  
  

  const handleDeleteUser = async (userID, investment_name) => {
    try {
      setLoading(true)
      const deleteUser = await remove(concatUrlWithId(DELETE_USER, userID));
      fetchUserList();
      setLoading(false)
      notify(`successfully deleted`, 'success')
    } catch (error) {
      console.log('Error while deleting the error : ', error)
    }

  };


  const handleUserStatus = async (userID, user_name) => {
    try {
      setLoading(true)
      const userStatus = await put(concatUrlWithId(USER_STATUS, userID));
      fetchUserList();
      setLoading(false)
      notify(`${user_name} ${userStatus.message}`, 'success')
    } catch (error) {
      console.log('Error while deleting the error : ', error)
    }

  };

  const handleResetPassword = async (userID, investment_name) => {
    try {
      setLoading(true)
      const deleteUser = await get(concatUrlWithId(RESET_PASSWORD, userID));
      fetchUserList();
      setLoading(false)
      notify(`Password reset link sent successfully.`, 'success')
    } catch (error) {
      console.log('Error while deleting the error : ', error)
    }

  };

  const deleteUser = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete user',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteUser(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }
  const updateStatus = (investment_id, investment_name, status) => {
    confirmAlert({
      title: 'User Status',
      message: `Are you sure you want to ${status == true ? 'deactivate' : 'activate'} the  ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleUserStatus(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const reSetUserPassword = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Reset Password',
      message: `Are you sure you want to reset the password ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleResetPassword(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  useEffect(() => {
    setLoading(true)
    // window.scrollTo(0, 0);
    authCheckAndUpdate();
  }, [location.state]);


  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'username', text: 'User Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'type'],
      headerWidth: '160px', url: '/user-info', action: true
    },
    { dataField: 'email', text: 'Email', sort: true },
    { dataField: 'manager', text: 'Role', sort: true, formatter: (cell) => cell ? 'User' : 'Manager', hidden: !checkPermission(userPermissions, 'isAdmin') },
    { dataField: 'manager_name', text: 'Manager Name', sort: true,  hidden: !checkPermission(userPermissions, 'isAdmin') },
    { dataField: 'phone', text: 'Phone No', sort: true },
    { dataField: 'last_login_time', text: 'Last Login',isDateAndTimeField:true, sort: true },
    { dataField: 'status', text: 'Status', sort: true, isStatusField: true },
  ];

    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: "User", handleOnClick: handleOnClickAction, onClickParams: ['id', 'username', 'status'], disableArchive: true, disableDelete: true, statusAction: true, resetPassword: true, };
  
    columns.push(tempAction);
    //   }
  return (<div className="main-content introduction-farm">
    <div className="content-wraper-area">
      <div className="dashboard-area">
        <div className="container-fluid">
          <div className="row" style={{ alignItems: 'flex-end', alignItems: 'center' }} >
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title" style={{ marginBottom: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <h4 style={{ flex: 'auto' }}>User List</h4>
                    {/* {
                    (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'create-stock')) && */}
                    <button type="button" onClick={handleAdd} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                    {/* } */}
                  </div>
                </div>
                <div >
                  <UserModelForm show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} headerName={'User'} />
                  {!loading && <Datatable data={UserList || []} columns={columns} csvFileName={'User'} isAdminOrManager={true} hideStandardAction={true} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}
export default UserList;